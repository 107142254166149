import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from 'gatsby'

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import styled from "styled-components";
import {Helmet} from "react-helmet";

const GalleryWrapper = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;

const GalleryPage = (data) => {
    const images = data.data.contentfulGalleryImages.galleryImages
    return (
        <>
            <Helmet>
                <title>Gallery - MEM Safety</title>
            </Helmet>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="8">
                                <Title variant="hero">Gallery</Title>
                                <Text>
                                    MEM Safety Gallery
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <GalleryWrapper className="pb-4 pb-lg-0">
                    <Gallery images={images} />
                </GalleryWrapper>
            </PageWrapper>
        </>
    );
};
export default GalleryPage;

export const query = graphql`
    {
        contentfulGalleryImages {
            name
            galleryImages{
                thumb: fluid(maxWidth: 1024, maxHeight: 1024) {
                    ...GatsbyContentfulFluid
                }
                full: fluid(maxWidth: 1024) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }
`
